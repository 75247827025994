export const SideBarTab = [
    {
        name: 'Home',
        icon: './images/home.png',
        link: '/home'
    },
    {
        name: 'Profile',
        icon: './images/profile.png',
        link: '/profile'
    },
    {
        name: 'Pocketbook',
        icon: './images/wallet.png',
        link: '/pocketbook'
    },
    {
        name: 'History',
        icon: './images/bid_history.png',
        link: '/history'
    },
    {
        name: 'FC History',
        icon: './images/bid_history.png',
        link: '/fc-history'
    },
    {
        name: 'Khanapara Pervious Results',
        icon: './images/bid_history.png',
        link: '/khanapara-result'
    },
    {
        name: 'Shilong Previous Results',
        icon: './images/bid_history.png',
        link: '/shilong-result'
    },
    {
        name: 'How to use',
        icon: './images/play.png',
        link: '/how-to-use'
    },
    {
        name: 'Rates',
        icon: './images/logout.png',
        link: '/rates'
    },
    {
        name: 'Contact Us',
        icon: './images/contact_us.png',
        link: '/contact-us'
    },
    // {
    //     name: 'Share',
    //     icon: './images/share.png',
    //     link: '/'
    // },
    // {
    //     name: 'Rate Us',
    //     icon: './images/rate.png',
    //     link: '/'
    // },
    {
        name: 'About Us',
        icon: './images/info.png',
        link: '/about-us'
    },
    {
        name: 'Log Out',
        icon: './images/logout.png',
        link: '/'
    },
]