import React, { useEffect, useState } from "react";
import Header from "../components/header";
import { API_KEY } from "../utils/constants";
import { playGameAPI } from "../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/loader";
import { toast } from "react-toastify";
import { amountValidation } from "../utils/validation";

function BSSingleNumberPlay() {
  const location = useLocation();
  const navigate = useNavigate();
  const { name, type } = location.state || {};
  const [gameNumber, setGameNumber] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let input = [];
    for (let i = 0; i < 100; i++) {
      let title = i.toString().padStart(2, "0");
      input.push({ id: i, title: title, value: "" });
    }
    setGameNumber(input);
  }, []);

  const updateGameValue = (index, newValue) => {
    // Create a new array with the updated object
    const updatedGameList = gameNumber.map((game, i) =>
      i === index ? { ...game, value: newValue } : game
    );

    // Update the state with the new array
    setGameNumber(updatedGameList);
    const totalValue = updatedGameList.reduce(
      (total, game) => Number(total) + Number(game.value),
      0
    );
    setTotal(totalValue);
  };

  const handleSubmit = async () => {
    const minamount = gameNumber.filter(
      (item) => Number(item.value) < 10 && item.value != ""
    );
    if (total == 0) {
      toast.error("Bid on at least one number");
    } else if (minamount.length != 0) {
      toast.error("Bid amount should be minimum 10");
    } else {
      await SubmitFR();
      await SubmitSR();
    }
  };

  const SubmitFR = async () => {
    setIsLoading(true);
    const userData = await localStorage.getItem("user");
    const user_uid = JSON.parse(userData).uid;
    const gameName =
      name == "KHANAPARA BS" ? "KHANAPARA TEER - FR" : "SHILLONG TEER - FR";
    const formData = new FormData();
    formData.append("__api_key__", API_KEY);
    formData.append("user_uid", user_uid);
    formData.append("game_name", gameName);
    formData.append("game_type", type);
    gameNumber.map((item, index) => {
      const value = Number(item.value) / 2 || "";
      formData.append(`field_${index}`, value);
    });
    await playGameAPI(formData)
      .then((res) => {
        if (res.data.state === "OK") {
          console.log(res.data);
          setIsLoading(false);
        }
      })
      .catch((error) => console.log(error));
  };

  const SubmitSR = async () => {
    setIsLoading(true);
    const userData = await localStorage.getItem("user");
    const user_uid = JSON.parse(userData).uid;
    const gameName =
      name == "KHANAPARA BS" ? "KHANAPARA TEER - SR" : "SHILLONG TEER - SR";
    const formData = new FormData();
    formData.append("__api_key__", API_KEY);
    formData.append("user_uid", user_uid);
    formData.append("game_name", gameName);
    formData.append("game_type", type);
    gameNumber.map((item, index) => {
      const value = Number(item.value) / 2 || "";
      formData.append(`field_${index}`, value);
    });
    await playGameAPI(formData)
      .then((res) => {
        if (res.data.state === "OK") {
          console.log(res.data);
          setIsLoading(false);
          navigate("/home");
          toast.success("Played Successfully");
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="main-2">
      <div className="col-12" style={{ position: "fixed", maxWidth: 450, zIndex: 100, top: 0 }}>
      <Header title="Single Number Play" bgColor isBack />
      </div>
      <div className="container" style={{paddingBottom: 150, paddingTop: 70}}>
        <div className="d-flex flex-wrap justify-content-around">
          {gameNumber.map((item, index) => (
            <div className="col-5 my-2">
              <label>{item.title}</label>
              <input
                className="form-control shadow-sm"
                placeholder="Enter"
                name="number"
                value={item.value}
                onChange={(e) => {
                  amountValidation.test(e.target.value) &&
                    updateGameValue(index, e.target.value);
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="col-12 bg-white p-2" style={{ position: "fixed", maxWidth: 450, zIndex: 100, bottom: 0 }}>
          <div
            className="d-flex justify-content-between w-100 align-items-center p-2 rounded-2"
            style={{ backgroundColor: "#6c62ff" }}
          >
            <label className="text-white fw-bold">Total:</label>
            <div className="col-2 p-2 bg-white text-center rounded-2">
              {total}
            </div>
          </div>
          <button
            className="play-button mt-3 btn"
            onClick={() => handleSubmit()}
          >
            Submit
          </button>
        </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default BSSingleNumberPlay;
