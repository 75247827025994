import React, { useEffect, useState } from "react";
import Header from "../components/header";
import { API_KEY } from "../utils/constants";
import { directFCGameAPI, playGameAPI } from "../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/loader";
import { toast } from "react-toastify";
import { amountValidation, twoDigitValidation } from "../utils/validation";

function DirectFC() {
  const location = useLocation();
  const navigate = useNavigate();
  const { name, type, game_uid } = location.state || {};
  const [leftDigit, setLeftDigit] = useState("");
  const [rightDigit, setRightDigit] = useState("");
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (leftDigit.length == 0 || rightDigit.length == 0) {
      toast.error("Please enter two digit number");
    } else if (amount == 0) {
      toast.error("Enter points");
    } else if (Number(amount) < 5) {
      toast.error("Bid amount should be minimum 5");
    } else {
      setIsLoading(true);
      const userData = await localStorage.getItem("user");
      const user_uid = JSON.parse(userData).uid;
      const formData = new FormData();
      formData.append("__api_key__", API_KEY);
      formData.append("user_uid", user_uid);
      formData.append("game_name", name);
      formData.append("game_type", type);
      formData.append("amount", amount);
      formData.append("left_digit", leftDigit);
      formData.append("right_digit", rightDigit);
      formData.append("game_uid", game_uid);
      await directFCGameAPI(formData)
        .then((res) => {
          if (res.data.state === "OK") {
            setIsLoading(false);
            navigate("/home");
            toast.success("Played Successfully");
          }
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div className="main-2">
      <Header title={type} bgColor isBack />
      <div
        className="container d-flex justify-content-between flex-column"
        style={{ height: "90vh" }}
      >
        <div className="d-flex flex-wrap rounded-3 justify-content-around align-items-center text-center shadow-sm p-4 my-4">
          <div className="col-5 my-2">
            <h5>FR</h5>
            <input
              className="form-control shadow-sm"
              placeholder="Enter"
              name="number"
              value={leftDigit}
              onChange={(e) => {twoDigitValidation.test(e.target.value) && setLeftDigit(e.target.value)}}
            />
          </div>
          <div>
            <i class="bi bi-x fs-1"></i>
          </div>
          <div className="col-5 my-2">
            <h5>SR</h5>
            <input
              className="form-control shadow-sm"
              placeholder="Enter"
              name="number"
              value={rightDigit}
              onChange={(e) => {twoDigitValidation.test(e.target.value) && setRightDigit(e.target.value)}}
            />
          </div>
        </div>
        <div>
          <div
            className="d-flex justify-content-between w-100 align-items-center p-2 rounded-2"
            style={{ backgroundColor: "#e6e7e8" }}
          >
            <label className="text-dark fw-bold text-center col-4">
              Enter:
            </label>
            <div className="col-4 p-2 rounded-2">
              <input
                className="form-control"
                name="number"
                placeholder="Enter"
                value={amount}
                onChange={(e) => {amountValidation.test(e.target.value) && setAmount(e.target.value)}}
              />
            </div>
          </div>
          <button
            className="play-button my-3 btn"
            onClick={() => handleSubmit()}
          >
            Submit
          </button>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default DirectFC;
