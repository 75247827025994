import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/home';
import OTPVerify from './pages/otpVerify';
import SignUp from './pages/signUp';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Login from './pages/login';
import GameDetail from './pages/gameDetail';
import SingleNumberPlay from './pages/singleNumberPlay';
import EndingPlay from './pages/endingPlay';
import HousePlay from './pages/housePlay';
import Profile from './pages/profile';
import Pocketbook from './pages/pocketbook';
import Add from './pages/add';
import Withdraw from './pages/withdraw';
import Bank from './pages/bank';
import WithdrawHistory from './pages/withdrawHistory';
import FCGame from './pages/fcGame';
import DirectFC from './pages/directFC';
import BothSideFC from './pages/bothSideFC';
import SameFC from './pages/sameFC';
import History from './pages/history';
import FCHistory from './pages/fcHistory';
import KhanaparaResult from './pages/khanaparaResult';
import ShilongResult from './pages/shilongResult';
import HowToUse from './pages/howToUse';
import ContactUs from './pages/contactUs';
import GameRate from './pages/gameRate';
import BSSingleNumberPlay from './pages/bsSingleNumberPlay';
import ForgotPassword from './pages/forgotPassword';
import ResetPassword from './pages/resetPassword';
import AboutUs from './pages/aboutUs';
import BsHousePlay from './pages/bsHousePlay';
import BsEndingPlay from './pages/bsEndingPlay';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/home' element={<Home />} />
        <Route path='/otp-verify' element={<OTPVerify />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/gamedetail' element={<GameDetail />} />
        <Route path='/singlenumberplay' element={<SingleNumberPlay />} />
        <Route path='/houseplay' element={<HousePlay />} />
        <Route path='/endingplay' element={<EndingPlay />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/pocketbook' element={<Pocketbook />} />
        <Route path='/add' element={<Add />} />
        <Route path='/withdraw' element={<Withdraw />} />
        <Route path='/withdraw-history' element={<WithdrawHistory />} />
        <Route path='/bank' element={<Bank />} />

        <Route path='/fc-game' element={<FCGame />} />
        <Route path='/direct-fc' element={<DirectFC />} />
        <Route path='/bothside-fc' element={<BothSideFC />} />
        <Route path='/same-fc' element={<SameFC />} />
        <Route path='/history' element={<History />} />
        <Route path='/fc-history' element={<FCHistory />} />
        <Route path='/khanapara-result' element={<KhanaparaResult />} />
        <Route path='/shilong-result' element={<ShilongResult />} />
        <Route path='/how-to-use' element={<HowToUse />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/rates' element={<GameRate />} />
        <Route path='/bs-singlenumberplay' element={<BSSingleNumberPlay />} />
        <Route path='/bs-houseplay' element={<BsHousePlay />} />
        <Route path='/bs-endingplay' element={<BsEndingPlay />} />
        <Route path='/about-us' element={<AboutUs />} />

      </Routes>
    </Router>
    <ToastContainer position='top-center' autoClose={3000} hideProgressBar />
    </>
  );
}

export default App;
