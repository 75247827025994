import React, { useEffect, useState } from "react";
import Header from "../components/header";
import { API_KEY } from "../utils/constants";
import { playGameAPI, sameFCGameAPI } from "../api/api";
import { json, useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/loader";
import { toast } from "react-toastify";
import { amountValidation, twoDigitValidation } from "../utils/validation";

function SameFC() {
  const location = useLocation();
  const navigate = useNavigate();
  const { name, type, game_uid } = location.state || {};
  const [amount, setAmount] = useState("");
  const [total, setTotal] = useState(0);
  const [leftSideData, setLeftSideData] = useState(["", "", ""]);
  const [isLoading, setIsLoading] = useState(false);

  const handleLeft = (index, newValue) => {
    const updatedGameList = leftSideData.map((game, i) =>
      i === index ? newValue : game
    );

    // Update the state with the new array
    setLeftSideData(updatedGameList);
    setAmount("");
    setTotal("");
  };

  const handleAmount = (value) => {
    setAmount(value);
    const newPairs = [];

    // Generate pairs from data1 to data2
    for (let i = 0; i < leftSideData.length; i++) {
      for (let j = 0; j < leftSideData.length; j++) {
        newPairs.push(leftSideData[i] + leftSideData[j]);
      }
    }
    setTotal(Number(newPairs.length) * Number(value));
  };

  const handleSubmit = async () => {
    const left = leftSideData.filter((item) => item == "");
    if (left.length) {
      toast.error("Fields cannot be empty");
    } else if (amount == 0) {
      toast.error("Enter points");
    } else if (Number(amount) < 5) {
      toast.error("Bid amount should be minimum 5");
    } else {
      setIsLoading(true);
      const leftData = { digits: leftSideData };
      const userData = await localStorage.getItem("user");
      const user_uid = JSON.parse(userData).uid;
      const formData = new FormData();
      formData.append("__api_key__", API_KEY);
      formData.append("user_uid", user_uid);
      formData.append("game_name", name);
      formData.append("game_type", type);
      formData.append("amount", amount);
      formData.append("left_digit_data", JSON.stringify(leftData));
      formData.append("right_digit_data", "");
      formData.append("game_uid", game_uid);
      formData.append("total", total);
      await sameFCGameAPI(formData)
        .then((res) => {
          if (res.data.state === "OK") {
            navigate("/home");
            toast.success("Played Successfully");
            setIsLoading(false);
          }
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div className="main-2">
      <div
        className="col-12"
        style={{ position: "fixed", maxWidth: 450, zIndex: 100, top: 0 }}
      >
        <Header title={type} bgColor isBack />
      </div>
      <div
        className="container d-flex justify-content-between flex-column"
        style={{
          minHeight: "90vh",
          backgroundColor: "white",
          paddingTop: 60,
          paddingBottom: 200,
        }}
      >
        <div className="d-flex flex-wrap rounded-3 justify-content-around text-center my-4">
          <div className="col-4">
            {leftSideData.map((item, index) => (
              <input
                className="form-control shadow-sm my-2"
                placeholder="00"
                name="number"
                value={item}
                onChange={(e) => {
                  twoDigitValidation.test(e.target.value) &&
                    handleLeft(index, e.target.value);
                }}
              />
            ))}
            {leftSideData.length < 20 && (
              <button
                className="btn play-button rounded-5"
                onClick={() => setLeftSideData([...leftSideData, ""])}
              >
                Add +
              </button>
            )}
          </div>
        </div>
      </div>
      <div
        className="col-12 bg-white"
        style={{ position: "fixed", maxWidth: 450, zIndex: 100, bottom: 0 }}
      >
        <div
          className="d-flex justify-content-between w-100 align-items-center p-2 rounded-2"
          style={{ backgroundColor: "#e6e7e8" }}
        >
          <label className="text-dark fw-bold text-center col-4">Enter:</label>
          <div className="col-4 p-2 rounded-2">
            <input
              className="form-control"
              placeholder="Enter"
              name="number"
              value={amount}
              onChange={(e) => {
                amountValidation.test(e.target.value) &&
                  handleAmount(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          className="d-flex justify-content-between w-100 align-items-center p-2 rounded-2"
          style={{ backgroundColor: "#e6e7e8" }}
        >
          <label className="text-dark fw-bold text-center col-4">Total:</label>
          <div className="col-4 p-2 rounded-2">
            <h6>{total}</h6>
          </div>
        </div>
        <div className="px-2">
          <button
            className="play-button my-2 btn"
            onClick={() => handleSubmit()}
          >
            Submit
          </button>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default SameFC;
