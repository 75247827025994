/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

function ImageSlider({ images }) {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const interval = setInterval(nextImage, 3000); // Change image every 3 seconds
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [images]);

  return (
        <div>
          <img
            className='img-fluid'
            style={{ width: '100%', height: 192, objectFit: 'cover', borderRadius: 50 }} 
            src={images[currentImageIndex]?.url}
            alt='banner img'
          />
        </div>
  );
}

export default ImageSlider;
