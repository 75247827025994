import React, { useEffect, useState } from 'react';
import { API_KEY } from '../utils/constants';
import { userDataAPI } from '../api/api';
import { SideBarTab } from '../utils/sideBarTab';
import { useNavigate } from 'react-router-dom';

function SideBar({ open, setOpen }) {
    const navigate = useNavigate();
  const [user, setUser] = useState('');
  const getUserData = async () => {
    const userData = await localStorage.getItem('user');
    const user_uid = JSON.parse(userData)?.uid;

    const formData = new FormData();
    formData.append('__api_key__', API_KEY);
    formData.append('user_uid', user_uid);
    await userDataAPI(formData)
      .then((res) => {
        if (res.data.state === 'OK') {
          setUser(res.data.data.user_entity);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className={`side-panel ${open ? 'open' : ''}`}>
      <div
        style={{
          backgroundColor: '#6c62ff',
          borderEndEndRadius: 20,
          paddingBottom: 20,
        }}
      >
        <div className='side-panel-header'>
          <i
            class='bi bi-x-circle-fill fs-3 text-white'
            onClick={() => setOpen(false)}
            style={{cursor: 'pointer'}}
          />
        </div>
        <div className='d-flex justify-content-center align-items-center'>
          <img
            src='./images/avatar.png'
            alt='profile'
            style={{
              width: 50,
              height: 50,
              borderRadius: 50,
              backgroundColor: '#fff',
              padding: 2,
            }}
          />
          <div className='ps-3'>
            <h6 className='m-0 text-white'>{user.username}</h6>
            <h6 className='m-0 text-white'>{user.phone}</h6>
          </div>
        </div>
      </div>
      <div className='side-panel-content'>
        {SideBarTab.map((tab) => (
          <div className='d-flex align-items-center justify-content-start px-3 py-2' style={{cursor: 'pointer'}} onClick={()=> {navigate(tab.link);  setOpen(false); tab.name == 'Log Out' && localStorage.clear()}}>
            <img
              src={tab.icon}
              style={{ height: (tab.name == 'Home' || tab.name == 'About Us') ? 20 : 35, padding: (tab.name == 'Home' || tab.name == 'About Us')? '0 5px': 0, width: 'auto' }}
              alt='icon'
            />
            <span className='fs-5 text-start ps-2'>{tab.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SideBar;
